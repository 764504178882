import React from 'react';


export class DataService extends React.Component {
    static dataOrders = [];
    static users = [];
    static options = [];
    static stock = [];

    static setDataOrders (dataOrders) {
        this.dataOrders = dataOrders;
        this.setUsers(dataOrders);
        this.setListForSearcher(dataOrders);
    }

    static setStock(stock) {
        this.stock = stock;
    }
    
    static setUsers(dataOrders) {
        // Logica para agarrar users de data ordes.
    }

    static setListForSearcher(dataOrders){
        let options = [];
        for (let i = 0; i < dataOrders.length; i++) {
            let data = {
                label: this.getLabel(dataOrders[i]),
                element: dataOrders[i]
            }

            options.push(data);
        }

        this.options = options;
    }

    static getLabel(data) {
        let label = "#";

        label += data.sobre + ". ";
        label += "Tel: " + data.telefono + ". ";
        label += data.apellido_y_nombre + ".";


        return label;
    }

    static getStock() {
        let array = [
            {
                codigo: 1,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 2,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 3,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 4,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 5,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 6,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 7,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 21560,
                stock: 1
            },
            {
                codigo: 8,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 2000,
                stock: 1
            },
            {
                codigo: 9,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 3333,
                stock: 13
            },
            {
                codigo: 10,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 11,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 12,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 13,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 14,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 15,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 16,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 17,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 18,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 19,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            },
            {
                codigo: 120,
                marca: 'Vulk',
                modelo: 'Modelo',
                color: 'rojo',
                precio: 1000,
                stock: 10
            }
        ];


        return array;
    }
}
