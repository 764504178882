import { useState, useEffect } from "react";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import PrivateRoute from "./routes/private-route";
import { collection, query, where, onSnapshot, getDocs, addDoc } from "firebase/firestore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./views/login";

import  {db, auth}  from './firebase/firebase';
import Home from './views/home';
import { AuthProvider } from './firebase/auth';
import { PublicRoute } from './routes/public-route';
import { DataService } from './services/data-service';
import AddProduct from './views/addProduct';
import ViewGastos from './views/viewGastos';
import Constantes from './views/constants';
import Simulador from './views/simulador';
import AddProductFriend from "./views/addProductFriend";
import Metas from "./views/metas";
import AishaGastos from "./views/aishaGastos";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  useEffect(()=>{
    console.log(1)
    document.onkeydown = function (e) {
      return (e.which || e.keyCode) !== 116;
  }

  window.addEventListener("beforeunload", ()=> {
    return false
  })
    loadData();
  }, []);


  const loadData = () => {
    onSnapshot(collection(db, 'ordenes-dev'), (querySnapshot) => {
      const orders = [];
      querySnapshot.forEach((doc) => {
          orders.push(doc.data());
      });
      DataService.setDataOrders(orders);
     
      console.log("Actualizacion data.", orders)
    });

    onSnapshot(collection(db, 'stock-dev'), (querySnapshot) => {
      const stock = [];
      querySnapshot.forEach((doc) => {
          stock.push(doc.data());
      });
      DataService.setStock(stock);
     
      console.log("Actualizacion stock.", stock)
    });

  }

  return (
    <AuthProvider>
      <Router>
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/viewGastos" component={ViewGastos} />
            <PrivateRoute exact path="/simulador" component={Simulador} />
            <PrivateRoute exact path="/addProduct" component={AddProduct} />
            <PrivateRoute exact path="/aisha-add-product" component={AddProduct} />
            <PrivateRoute exact path="/aisha-productos" component={AishaGastos} />

            <PrivateRoute exact path="/addProductFriend" component={AddProductFriend} />
            <PrivateRoute exact path="/objetivos" component={Metas} />
            <PrivateRoute exact path="/aisha-home" component={Metas} />


            <PublicRoute exact path="/login" component={Login} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
