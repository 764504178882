import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {app,auth} from "../firebase/firebase";
import { ARRAY } from "../utils/base-datos";
import {db} from "../firebase/firebase";
import { collection, query, where, onSnapshot, getDocs, addDoc, setDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { DataService } from "../services/data-service";
import { Autocomplete, Box, Button, Checkbox, IconButton, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from "../firebase/auth";
import { ColorModeContext, tokens, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";

const Simulador = () => {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [nombre, setNombre] = useState("");
  const [cuotaInicial, setCuotaInicial] = useState(0);
  const [cuotaFinal, setCuotaFinal] = useState(0);
  const [gastoFijo, setGastoFijo] = useState(false);
  const [unPago, setUnPago] = useState(false);


  const [products, setProducts] = useState([]);



  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState('NONE');
  const [dataModal, setDataModal] = useState({});
  const [constantValue, setConstantValue] = useState('');

  const [indexToUpdate, setIndexToUpdate] = useState(0);
  const [constants, setConstants] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const {currentUser} = useContext(AuthContext);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
     getConstants();
  }, [indexToUpdate]);

  const getConstants = () => {
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = collection(docRef, "values");
    onSnapshot(colRef, (querySnapshot) => {
        setIsLoading(true);
        const _constants = [];
        querySnapshot.forEach((doc) => {
            let copyItem = Object.assign({}, doc.data());
            copyItem.internalId = doc.id;
            _constants.push(copyItem);

        });
       
        setConstants(_constants);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
      });
  }
  
  const addConstant = () => {
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", constantValue)

    // addDoc(colRef, {name: constantValue, value: null}).then(e => {
    //   console.log("Add constant succesfully!");
    //     setIndexToUpdate(indexToUpdate++);
    //     setConstantValue('');
    // });
    setDoc(colRef, {name: constantValue, value: null}).then(e => {
        console.log("Add constant succesfully!");
        setIndexToUpdate(0);
        setConstantValue('');
    });
  }
  
  const updateValueConstant = (value, nameConstant) => {
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", nameConstant)
    updateDoc(colRef, {
        value: value
      }).then(e => {
          console.log("Ok");
          setIndexToUpdate(0);
      });
  }

  const changeData = (_const, newValue) => {
    let newConstants = [];
    for (let i = 0; i < constants.length; i++){
        if (constants[i].name === _const.name) {
            let copyObject = Object.assign(constants[i]);
            copyObject.value = newValue;
            newConstants.push(copyObject);
        }else {
            newConstants.push(constants[i])
        }
    }

    setConstants(newConstants);
  }

  const deleteConstants = (_const) => {
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", _const.name)

    deleteDoc(colRef).then(e => {
        console.log("Constans eliminada.");
        setIndexToUpdate(0);
    });
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
              <Container style={{background: colors.primary[400] }}>
                <MainWithDetail>
                  <Main>
                    <TileContainer style={{background: colors.primary[400] }}>
                      <div style={{display: 'flex', gap: '12px'}}>
                          <TextField id="outlined-basic" value={constantValue} onChange={(e)=> {setConstantValue(e.target.value)}} label="Constante" variant="outlined" />
                          <Button variant="contained" onClick={()=> {setShowModal(true); setTypeModal('CONFIRM_ADD_CONSTANT');}}>Agregar constante</Button>
                      </div>
                      <div style={{display: 'flex', flexDirection:'column', gap: '16px'}}>
                          {
                            !isLoading ? constants.map(_const => {
                                  return <div style={{display: 'flex', gap: '12px', alignItems: 'center'}} key={_const.name}>
                                      <TextField id="outlined-basic" value={_const.value} label={_const.name} onChange={(e)=> {changeData(_const, e.target.value)}} variant="outlined" />
                                      <Button style={{height:"100%"}} variant="contained" onClick={()=> {updateValueConstant(_const.value, _const.name)}}>CAMBIAR VALOR</Button>
                                      <DeleteIcon fontSize="large" style={{cursor: 'pointer'}} onClick={()=>{deleteConstants(_const)}}></DeleteIcon>
                                  </div>
                              }) : null
                          }
                      </div>



                      <Modal
                          open={showModal}
                          onClose={(e)=> {setShowModal(false); setTypeModal('NONE');}}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          >
                          <Box sx={style}>
                              <Typography id="modal-modal-title" variant="h6" component="h2">
                              { typeModal === 'CONFIRM_ADD_CONSTANT' ? <span>Desea agregar la constante {constantValue} ? </span> : null}
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                              {typeModal === 'CONFIRM_ADD_CONSTANT' ?
                              <div style={{display: 'flex', gap: '12px'}}>
                                  <Button variant="outlined" onClick={()=> {setShowModal(false); setTypeModal('NONE');}}>CANCELAR</Button>
                                  <Button variant="contained" onClick={()=> {setShowModal(false); setTypeModal('NONE'); addConstant()}}>AGREGAR CONSTANTE</Button>
                              </div>: null}
                              </Typography>
                          </Box>
                      </Modal>

                      <Tooltip title={<h1 style={{ color: "lightblue" }}>Para tarjetas crea la constante, 'TARJETAS'. Luego de crearla pon valores separados por comas. Ej: VISA,AMEX,KOPETC</h1>} style={{position: 'fixed', right: '12px', bottom: '12px'}}>
                        <IconButton>
                          <img src='https://upload.wikimedia.org/wikipedia/en/d/d8/Windows_11_Clippy_paperclip_emoji.png' style={{width: '80px'}}></img>
                        </IconButton>
                      </Tooltip>
                      
                      
                    </TileContainer>
                  </Main>
                  
                </MainWithDetail>
              </Container>
              </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%; 
  background: gray;
`;

const TileContainer = styled.div`
  display: flex;
  height: calc(100vh-24px);
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 16px;
  margin: 12px;
  border-radius: 6px;
`;

const MainWithDetail = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;


export default Simulador;