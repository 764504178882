import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {db, auth} from "../firebase/firebase";
import { collection, query, where, onSnapshot, getDoc, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { DataService } from "../services/data-service";
import { TextField, Box, FormControlLabel, Checkbox, Select, MenuItem, Button, Switch, IconButton, Tooltip } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { AuthContext } from "../firebase/auth";


const AddProduct = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [nombre, setNombre] = useState('');
  const [celular, setCelular] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [adelanto, setAdelanto] = useState('');
  const [total, setTotal] = useState('');

  const [gasto, setGasto] = useState('');
  const [isFijo, setIsFijo] = useState(false);
  const [quotesLeft, setQuotesLeft] = useState ('');
  const [tarjeta, setTarjeta] = useState('VISA');
  const colors = tokens(theme.palette.mode);
  const {currentUser} = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);

  const [tarjetas, setTarjetas] = useState(['VISA', 'AMERICAN EXPRESS']);
  
  const [parcial, setParcial] = useState(false);
  
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);

  const [isAisha, setIsAisha] = useState(false);

  useEffect(()=> {
    if (window.location?.href?.indexOf('aisha') >= 0) {
      setIsAisha(true);
    }

    const fetchData = async () => {
        const q = query(collection(db, "users"));
      console.log("sdads")
        const querySnapshot = await getDocs(q);

        let _users = [];
        querySnapshot.forEach((doc) => {
          _users.push(doc.data());
        });

        setUsers(_users);
    }
    
    fetchData();

    setShowLoader(true);
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", 'TARJETAS');

    const docSnap = getDoc(colRef);

    docSnap.then(e => {
      if (e?.data()?.value) {
        let tc = e.data().value;
        let arr = tc.split(',')
        arr = arr.map(e=> e.trim()) ;
        setTarjetas(arr)
        setShowLoader(false);
      }
    })

    setTimeout(() => {
      setShowLoader(false);
    }, 4000);

  }, []);
  const handleChange = (event) => {
    setTarjeta(event.target.value);
  };
  
  const submit = (isAisha) => {
    if (!isAisha) {
      console.log("--------------------------------------------------------------");
      console.log("Nombre: ", nombre);
      console.log("Gasto: ", gasto);
      console.log("Is fijo: ", isFijo);
      console.log("Cuotas restantes: ", quotesLeft);
      console.log("Tarjeta: ", tarjeta);
      console.log("Parcial: ", parcial);
      console.log("User: ", user)
      console.log("--------------------------------------------------------------");
  
        const docRef = doc(db, "products", currentUser.email );
        const colRef = collection(docRef, "purchases")
        let _gasto = gasto;
  
        if (!parcial) {
          _gasto = gasto / quotesLeft;
        }
        addDoc(colRef, {
          nombre, gasto: _gasto, isFijo, quotesLeft, tarjeta, date: new Date()
        }).then(e => {
          setEmpty();
        });
    } else {
      const colRef = collection(db, "aisha-pedidos")
      let _gasto = gasto;
  
        if (!parcial) {
          _gasto = gasto / quotesLeft;
        }
        addDoc(colRef, {
          nombre, celular, date, dateCreated: new Date(), description, adelanto, total
        }).then(e => {
          console.log("Listoo");
          setEmpty();
        });
    }
 
    }

  const setEmpty = () => {
    setNombre('');
    setGasto('');
    setIsFijo('');
    setQuotesLeft('');
    setTarjeta('VISA');
    setParcial(false);
  }
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Container style={{background: colors.primary[400] }}>
            <MainWithDetail>
              <Main>
                
                <TileContainer className={[isAisha ? 'aisha-page' : ''].join(' ')} style={{background: colors.primary[400] }}>
                {
                  !showLoader && isAisha &&
                  <ContainerDetail>
                    <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nombre} onChange={(e)=>{setNombre(e.target.value)}} />
                    <TextField id="outlined-basic" label="Celular" variant="outlined" value={celular} onChange={(e)=>{setCelular(e.target.value)}} />
                    <TextField id="outlined-basic" label="Fecha de entrega" variant="outlined" value={date} onChange={(e)=>{setDate(e.target.value)}} />
                    <TextField id="outlined-basic" label="Seña" variant="outlined" value={adelanto} onChange={(e)=>{setAdelanto(e.target.value)}} />
                    <TextField id="outlined-basic" label="Total" variant="outlined" value={total} onChange={(e)=>{setTotal(e.target.value)}} />
                    <TextField
                      id="outlined-multiline-static"
                      label="Descripcion"
                      value={description}
                      onChange={(e)=>{setDescription(e.target.value)}}
                      multiline
                      rows={10}
                      defaultValue="Default Value"
                    />

                  <Button variant="contained" onClick={() => {submit(true)}}>AGREGAR PEDIDO</Button>

                  </ContainerDetail>
                }
                

                {
                  !showLoader && !isAisha && 
                <ContainerDetail>
                  <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nombre} onChange={(e)=>{setNombre(e.target.value)}} />
                  <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><TextField id="outlined-basic" label="Gasto" variant="outlined" value={gasto} onChange={(e)=>{setGasto(e.target.value)}} /> <FormControlLabel control={<Checkbox  value={parcial} onChange={(e) => {setParcial(e.target.checked);}}/>} label="Valor cuota" /><Tooltip title={<h3 style={{ color: "lightblue" }}>Al activar este check, en el campo gasto tiene que poner el valor de 1 cuota. Sino por default ingrese el valor total del producto. La app lo va a dividir entre las cuotas.</h3>} style={{display: 'block', marginLeft: '-16px'}}>
                        <IconButton>
                          <InfoIcon></InfoIcon>
                        </IconButton>
                      </Tooltip>
                  </div>
                  <div className="container-gastos">
                    <TextField id="outlined-basic" label="Cuotas restantes" disabled={isFijo} variant="outlined" value={quotesLeft} onChange={(e)=>{setQuotesLeft(e.target.value)}} />
                    <FormControlLabel control={<Checkbox  value={isFijo} onChange={(e) => {setIsFijo(e.target.checked); setQuotesLeft('');}}/>} label="Es un gasto fijo" />
                  </div>
                  <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={tarjeta}
                  onChange={handleChange}
                  label="Tarjeta"
                >
                  {tarjetas.map(tarj => {
                    return <MenuItem value={tarj}>{tarj}</MenuItem>
                  })}
                  </Select>
                  <Button variant="contained" onClick={submit}>AGREGAR</Button>

                </ContainerDetail>
              }
                </TileContainer>
              </Main>
            </MainWithDetail>
          </Container>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container-gastos {
    display: flex;
    gap: 8px;
  }
`
const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%; 
`;

const TileContainer = styled.div`
  display: flex;
  height: calc(100vh-24px);
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 12px;
  border-radius: 6px;
  &.aisha-page {
    justify-content: flex-start;
    align-items: inherit;
  }
`;

const MainWithDetail = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const Side = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 40%;
  justify-content: center;
  padding: 20px;
`;


export default AddProduct;