import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Paper from '@mui/material/Paper';
import CreateIcon from '@mui/icons-material/Create';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {db} from "../firebase/firebase";
import { collection, query, where, onSnapshot, getDoc, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { DataService } from "../services/data-service";
import { TextField, Box, FormControlLabel, Checkbox, Select, MenuItem, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel } from "@mui/material";
import { DateTime } from "luxon";
import { ColorModeContext, tokens, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { AuthContext } from "../firebase/auth";

const ViewGastos = () => {

  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  const [nombre, setNombre] = useState('');
  const [gasto, setGasto] = useState('');
  const [isFijo, setIsFijo] = useState(false);
  const [quotesLeft, setQuotesLeft] = useState ('');
  const [tarjeta, setTarjeta] = useState('VISA');


  const [tarjetas, setTarjetas] = useState(['VISA', 'AMERICAN EXPRESS']);
  const [tarjetasFiltro, setTarjetasFiltro] = useState(['TODAS', 'VISA', 'AMERICAN EXPRESS']);

  const [filterTarjet, setFilterTarjet] = useState('TODAS');
  const [internalId, setInternalId] = useState('');
  const [products, setProducts] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [enumMont, setEnumMonth] = useState({1: 'Enero', 2:'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'});

  const [monthActual, setMonthActual] = useState(enumMont[new Date().getMonth() + 1] + ' ' + new Date().getFullYear());
  const [monthList, setMonthList] = useState([
    {value: 4, name: 'Abril 2023', valueMonth: 4, year: 2023}, {value: 5, name: 'Mayo 2023', valueMonth: 5, year: 2023}, {value: 6, name: 'Junio 2023', valueMonth: 6, year: 2023}, {value: 7, name: 'Julio 2023', valueMonth: 7, year: 2023}, {value: 8, name: 'Agosto 2023', valueMonth: 8, year: 2023}, {value: 9, name: 'Septiembre 2023', valueMonth: 9, year: 2023}, {value: 10, name: 'Octubre 2023', valueMonth: 10, year: 2023}, {value: 11, name: 'Noviembre 2023', valueMonth: 11, year: 2023}, {value: 12, name: 'Diciembre 2023', valueMonth: 12, year: 2023},
    {value: 13, name: 'Enero 2024', valueMonth: 1, year: 2024}, {value: 14, name: 'Febrero 2024', valueMonth: 2, year: 2024}, {value: 15, name: 'Marzo 2024', valueMonth: 3, year: 2024}, {value: 16, name: 'Abril 2024', valueMonth: 4, year: 2024}, {value: 17, name: 'Mayo 2024', valueMonth: 5, year: 2024}, {value: 18, name: 'Junio 2024', valueMonth: 6, year: 2024}, {value: 19, name: 'Julio 2024', valueMonth: 7, year: 2024}, {value: 20, name: 'Agosto 2024', valueMonth: 8, year: 2024}, {value: 21, name: 'Septiembre 2024', valueMonth: 9, year: 2024}, {value: 22, name: 'Octubre 2024', valueMonth: 10, year: 2024}, {value: 23, name: 'Noviembre 2024', valueMonth: 11, year: 2024}, {value: 24, name: 'Diciembre 2024', valueMonth: 12, year: 2024}]);
  const {currentUser} = useContext(AuthContext);

  const [originalListProducts, setOriginalListProducts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(()=> {
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", 'TARJETAS');

    const docSnap = getDoc(colRef);

    docSnap.then(e => {
      if (e?.data()?.value) {
        let tc = e.data().value;
        let arr = tc.split(',')
        arr = arr.map(e=> e.trim()) ;
        setTarjetas(arr)

        let copyArr = Object.assign([], arr);
        setTarjetasFiltro(copyArr)
      }
    })
  }, []);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let value = params.filterTc;

    reloadProducts(null, value);

    //getProducts();
  }, []);

  const reloadProducts = (value, filtroTarjeta) => {
    console.log("HOLAAA")
    setShowLoader(true);
    let actualDate = null;
    let actualMonth = value ? value : monthActual;
    let _filterTarjet = filtroTarjeta ? filtroTarjeta : filterTarjet;
    // setShowLoader(true);
    for (let i = 0; i < monthList.length; i++) {
      if (monthList[i].name === actualMonth) {
        actualDate = new Date(monthList[i].valueMonth.toString() + '/' + 1 + '/'  + monthList[i].year.toString());
        console.log('actualDate: ', actualDate);
        break;
      }
    }
    getProducts(actualDate, _filterTarjet);
    // setShowLoader(false);
    return actualDate;
  }


  const _filter = (value, filtroTarjeta) => {
    setShowLoader(true);
    console.log("FILTROOOOOOOOO: ", filtroTarjeta)
    let productos = [];
    let actualDate;
    let actualMonth = value ? value : monthActual;

    let copyOriginalList = Object.assign([], originalListProducts);

    for (let i = 0; i < monthList.length; i++) {
      if (monthList[i].name === actualMonth) {
        actualDate = new Date(monthList[i].valueMonth.toString() + '/' + 1 + '/'  + monthList[i].year.toString());
        break;
      }
    }

    copyOriginalList = copyOriginalList.filter(c => { return filtroTarjeta === c.tarjeta});
    console.log("COP: ",copyOriginalList)
    // for (let i = 0; i < originalListProducts.length; i++) {
    //   let copyItem = Object.assign({}, originalListProducts[i]);

    //   let dateProduct = new Date(DateTime.fromSeconds(copyItem.date.seconds));
    //   let diffMonth = Math.abs(monthDiff(dateProduct, actualDate));

    //   console.log("LLLLL: ",copyItem.tarjeta.toString().toLowerCase())
    //   console.log("LLLLLs: ",filtroTarjeta.toString().toLowerCase())
    //   if (copyItem.tarjeta.toString().toLowerCase() === filtroTarjeta.toString().toLowerCase()) {
    //     productos.push(copyItem);
    //   }
    //   // if ( (copyItem.quotesLeft - diffMonth) > 0 || copyItem.isFijo) {
        
    //   //   if (!copyItem.isFijo){
    //   //     copyItem.quotesLeft = copyItem.quotesLeft - diffMonth;
    //   //   }
    //   // if (copyItem.tarjeta.toString().toLowerCase() === filtroTarjeta.toString().toLowerCase()) {
    //   //     productos.push(copyItem);
    //   // }
    //   // }
    // }


    setProducts(copyOriginalList);

    setTimeout(() => {
      setShowLoader(false);

    }, 2000)
  }
  const getProducts = (actualDate, _filterTarjet) => {
    const docRef = doc(db, "products", currentUser.email );
    const colRef = collection(docRef, "purchases");

    onSnapshot(colRef, (querySnapshot) => {

      const _products = [];
      const _originalList = [];
      querySnapshot.forEach((doc) => {
        let copyItem = Object.assign({}, doc.data());
        copyItem.internalId = doc.id;

        let dateProduct = new Date(DateTime.fromSeconds(copyItem.date.seconds));
        let diffMonth = Math.abs(monthDiff(dateProduct, actualDate));

        _originalList.push(copyItem);
        if ( (copyItem.quotesLeft - diffMonth) > 0 || copyItem.isFijo) {

          if (!copyItem.isFijo){
            copyItem.quotesLeft = copyItem.quotesLeft - diffMonth;
          }

          if (_filterTarjet === 'TODAS' || copyItem.tarjeta.toLowerCase() === _filterTarjet.toLowerCase()){
             _products.push(copyItem);
           }

        }
      });
     
      localStorage.setItem('products', JSON.stringify(_products));
      setOriginalListProducts(_originalList);
      setProducts(_products);
      console.log("Actualizacion data.", _products)
      setShowLoader(false);
    });
  }

  const monthDiff = (d1, d2) => {
    let months; 
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth(); 
    months += d2.getMonth();
     return months;
  }

  const handleChange = (event) => {
    setTarjeta(event.target.value);
  };

  const setEmpty = () => {
    setNombre('');
    setGasto('');
    setInternalId('');
    setIsFijo('');
    setQuotesLeft('');
    setTarjeta('VISA');
    setEditMode(false);
  }

  const submit = () => {
    console.log("--------------------------------------------------------------");
    console.log("Nombre: ", nombre);
    console.log("Gasto: ", gasto);
    console.log("Is fijo: ", isFijo);
    console.log("Cuotas restantes: ", quotesLeft);
    console.log("Tarjeta: ", tarjeta);
    console.log("--------------------------------------------------------------");

    const docRef = doc(db, "products", currentUser.email );
    const colRef = collection(docRef, "purchases");
    
    updateDoc(doc(colRef, internalId), {
      nombre, gasto, isFijo, quotesLeft, tarjeta
    }).then(e => {
        console.log("Ok");
        setEmpty();
    });
  }

  const deleteProduct = (product) => {
    
    const docRef = doc(db, "products", currentUser.email );
    const colRef = collection(docRef, "purchases");

    deleteDoc(doc(colRef, product.internalId)).then(e => {
        console.log("Borro");
    })
  }
  const openProduct = (product) => {
    console.log("Product: ", product)
    // Aca se va a editar
    setNombre(product.nombre);
    setGasto(product.gasto);
    setQuotesLeft(product.quotesLeft);
    setIsFijo(product.isFijo);
    setTarjeta(product.tarjeta);
    setInternalId(product.internalId);
    setEditMode(true);
  }

  function parsePrice(number){
    let formatter = new Intl.NumberFormat('es-AR', {currency: 'ARS', style: 'currency'});
    return formatter.format(number);
  }
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Container>
            <MainWithDetail>
              <Main>
                <TileContainer>
                <TableContainer component={Paper} style={{background: colors.primary[400] }}>
                    <div style={{display: 'flex'}}>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-helper-label">Mes</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={monthActual}
                                label="Mes"
                                onChange={(e)=> {setMonthActual(e.target.value); reloadProducts(e.target.value, null);}}>
                                {monthList.map(month => {
                                    return <MenuItem value={month.name}>{month.name}</MenuItem>

                                })}
                            
                            </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-helper-label">Tarjeta</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={filterTarjet}
                                label="Tarjeta"
                                onChange={(e)=> {setFilterTarjet(e.target.value); _filter(null, e.target.value);}}>
                                {tarjetasFiltro.map(tarjeta => {
                                    return <MenuItem value={tarjeta}>{tarjeta}</MenuItem>

                                })}
                            
                            </Select>
                            </FormControl>
                        </div>
                    </div>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell align="left">Nombre</TableCell>
                            <TableCell align="center">Gasto $</TableCell>
                            <TableCell align="center">Cuotas restantes</TableCell>
                            <TableCell align="center">Tarjeta</TableCell>
                            <TableCell align="center">Borrar</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {!showLoader ?  products.map((row) => (
                            <TableRow
                            key={row.nombre}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="left" onClick={() => {openProduct(row)}}>{DateTime.fromSeconds(row.date.seconds).toLocaleString()}</TableCell>

                            <TableCell component="th" scope="row" onClick={() => {openProduct(row)}}>
                                {row.nombre}
                            </TableCell>
                            <TableCell align="center" onClick={() => {openProduct(row)}}>{parsePrice(parseInt(row.gasto))}</TableCell>
                            <TableCell align="center" onClick={() => {openProduct(row)}}>{row.quotesLeft}</TableCell>
                            <TableCell align="center" onClick={() => {openProduct(row)}}>{row.tarjeta}</TableCell>
                            <TableCell align="center" onClick={() => {deleteProduct(row)}}>X</TableCell>

                            </TableRow>
                        )) : null}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </TileContainer>
              </Main>
              <Side>
                {editMode ? <ContainerDetail>
                    <CloseTwoToneIcon style={{position: 'absolute', top: '16px', right: '16px', cursor: 'pointer'}} onClick={setEmpty}></CloseTwoToneIcon>
                  <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nombre} onChange={(e)=>{setNombre(e.target.value)}} />
                  <TextField id="outlined-basic" label="Gasto" variant="outlined" value={gasto} onChange={(e)=>{setGasto(e.target.value)}} />
                  <div className="container-gastos">
                    <TextField id="outlined-basic" label="Cuotas restantes" disabled={isFijo} variant="outlined" value={quotesLeft} onChange={(e)=>{setQuotesLeft(e.target.value)}} />
                    <FormControlLabel control={<Checkbox  value={isFijo} onChange={(e) => {setIsFijo(e.target.checked); setQuotesLeft('');}}/>} label="Es un gasto fijo" />
                  </div>
                  <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={tarjeta}
                  onChange={handleChange}
                  label="Tarjeta"
                >
                  {tarjetas.map(tarj => {
                    return <MenuItem value={tarj}>{tarj}</MenuItem>
                  })}
                  </Select>
                  <Button variant="contained" onClick={submit}>EDITAR</Button>

                </ContainerDetail> : 
                <EmptyState>
                    Edita tus gastos!
                </EmptyState>
                }

              </Side>
            </MainWithDetail>
          </Container>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const EmptyState = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`
const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  .container-gastos {
    display: flex;
    gap: 8px;
  }
`
const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%; 
`;

const TileContainer = styled.div`
  display: flex;
  height: calc(100vh-24px);
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  margin: 12px;
  border-radius: 6px;
`;

const MainWithDetail = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const Side = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 40%;
  justify-content: center;
`;


export default ViewGastos;