import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../firebase/auth";
import { PATH_ROOT } from "../utils/constants";

export const PublicRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    return (
      <Route
        {...rest}
        render={(routeProps) =>
          currentUser ? (
            <Redirect to={PATH_ROOT} />
          ) : (
            <RouteComponent {...routeProps} />
          )
        }
      />
    );
  };