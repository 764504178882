import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {db, auth} from "../firebase/firebase";
import { collection, query, where, onSnapshot, getDoc, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { DataService } from "../services/data-service";
import { TextField, Box, FormControlLabel, Checkbox, Select, MenuItem, Button, Switch, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { AuthContext } from "../firebase/auth";
import CheckIcon from '@mui/icons-material/Check';

const AddProductFriend = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [nombre, setNombre] = useState('');
  const [gasto, setGasto] = useState('');
  const [isFijo, setIsFijo] = useState(false);
  const [quotesLeft, setQuotesLeft] = useState ('1');
  const [tarjeta, setTarjeta] = useState('VISA');
  const colors = tokens(theme.palette.mode);
  const {currentUser} = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);

  const [tarjetas, setTarjetas] = useState(['VISA', 'AMERICAN EXPRESS']);
  
  const [parcial, setParcial] = useState(false);
  
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('Seleccione un usuario');

  const [friendProducts, setFriendProducts] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [dataTemporal, setDataTemporal] = useState(null);

  const [indexToUpdate, setIndexToUpdate] = useState(0);
  useEffect(() => {
    const fetchDataFriendProducts = async () => {
      const docRef = doc(db, "friendProducts", currentUser.email );
      const colRef = collection(docRef, 'purchases');
      const q = query(colRef);
      const querySnapshot = await getDocs(q);

      let _friendProducts = [];
      querySnapshot.forEach((doc) => {
        let copyItem = Object.assign({}, doc.data());
        copyItem.internalId = doc.id;
        _friendProducts.push(copyItem);
      });

      setFriendProducts(_friendProducts);
    }
    fetchDataFriendProducts();

  }, []);
  useEffect(()=> {
    const fetchData = async () => {
        const q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);

        let _users = [];
        querySnapshot.forEach((doc) => {
          _users.push(doc.data());
        });

        setUsers(_users);
    }

   
    fetchData();

    setShowLoader(true);
    const docRef = doc(db, "constants", currentUser.email );
    const colRef = doc(docRef, "values", 'TARJETAS');

    const docSnap = getDoc(colRef);

    docSnap.then(e => {
      if (e?.data()?.value) {
        let tc = e.data().value;
        let arr = tc.split(',')
        arr = arr.map(e=> e.trim()) ;
        setTarjetas(arr)
        setShowLoader(false);
      }
    })

    setTimeout(() => {
      setShowLoader(false);
    }, 4000);

  }, []);
  const handleChange = (event) => {
    setTarjeta(event.target.value);
  };
  function parsePrice(number){
    let formatter = new Intl.NumberFormat('es-AR', {currency: 'ARS', style: 'currency'});
    return formatter.format(number);
  }
  const submit = () => {
    console.log("--------------------------------------------------------------");
    console.log("Nombre: ", nombre);
    console.log("Gasto: ", gasto);
    console.log("Is fijo: ", isFijo);
    console.log("Cuotas restantes: ", quotesLeft);
    console.log("Tarjeta: ", tarjeta);
    console.log("Parcial: ", parcial);
    console.log("User: ", user);
    console.log("--------------------------------------------------------------");


    if (user) {
      
      // Agrego el producto al user.
      const docRef = doc(db, "friendProducts", user );
      const colRef = collection(docRef, "purchases")
      let _gasto = gasto;

      if (!parcial) {
        _gasto = gasto / quotesLeft;
      }
      console.log("GASTO: ", gasto)
      addDoc(colRef, {
        nombre, gasto: _gasto, isFijo, quotesLeft, tarjeta: tarjeta, date: new Date(), email: currentUser.email, userPurchase: user
      }).then(e => {
        setEmpty();
      });
    }

    console.log("Users: ", users)

  }

  const setEmpty = () => {
    setNombre('');
    setGasto('');
    setIsFijo('');
    setQuotesLeft('');
    setTarjeta('VISA');
    setParcial(false);
  }




   const submitData = (data) => {
    const fetchData = async () => {
      const docRef = doc(db, "constants", data.userPurchase );
      const colRef = doc(docRef, "values", 'TARJETAS');

      const docSnap = await getDoc(colRef);
      
      return docSnap;
    }

    //Vamos a nuestro friendProducts
    const docRef = doc(db, "friendProducts", currentUser.email );
    //Agarramos el purchase que seleccionamos
    const colRef = doc(docRef, "purchases", data.internalId)
    // Actualizamos a confirmed el purchase
    updateDoc(colRef, {
        confirmed: true
      }).then(e => {

        
        console.log("Change friendProducts");
        // Me traigo las tarjetas para agregarlas en caso de que necesite agregar.
        fetchData().then(e =>{
          console.log(e.data())
          let tcs = Object.assign({}, e.data());

          if (tcs.value.indexOf(data.email) === -1) {
            tcs.value += ',' + data.email;
          }

          const docRef = doc(db, "constants", data.userPurchase );
          const colRef = doc(docRef, "values", tcs.name)
          updateDoc(colRef, {
              value: tcs.value
            }).then(e => {
                console.log("Add constants.");
                // Agregro producto al usuario

                const docRef = doc(db, "products", data.email );
                const colRef = collection(docRef, "purchases")
                
                addDoc(colRef, {
                  nombre: data.nombre, gasto: data.gasto, isFijo: data.isFijo, quotesLeft: data.quotesLeft, tarjeta: data.tarjeta, date: data.date, userPurchase: data.userPurchase
                }).then(e => {
                  console.log("Add product OK");
                  setShowModalConfirm(false);
                  setDataTemporal(null);
                  
                  let copyFriends = Object.assign([], friendProducts);
                  setFriendProducts([]);

                  copyFriends = copyFriends.map(fr => {
                    let copyFr = Object.assign({}, fr);
                    if (fr.internalId === data.internalId) {
                        copyFr.confirmed = true;
                    }

                    return copyFr;
                  })

                  setFriendProducts(copyFriends)
                });

            });
        });

      });
  }
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Container style={{background: colors.primary[400] }}>
            <MainWithDetail>
              <Main>
                <TileContainer style={{background: colors.primary[400] }}>
                <div style={{display: 'flex', flexDirection: 'column',gap: '12px'}}>
                {
                  friendProducts?.length > 0 ? friendProducts.map(fp =>{
                    return <div style={{display: 'flex', gap: '12px', fontSize:'18px', padding: '20px', alignItems: 'center', borderRadius: '8px', height: 'fit-content',background:colors.primary[500]}}>
                      <span>{fp.email}</span><span>{fp.nombre}</span><span>{parsePrice(parseInt(fp.gasto))}</span><span>{fp.quotesLeft}</span><span>{fp.tarjeta}</span>{fp.confirmed ? <CheckIcon></CheckIcon> : <Button variant="contained" style={{background:colors.primary[400]}} onClick={() => {setDataTemporal(fp); setShowModalConfirm(true)}}>AGREGAR</Button>}

                    </div>
                  }) : null
                  
                }
                </div>

                        <Dialog
                          open={showModalConfirm}
                          onClose={(e)=>{setShowModalConfirm(false)}}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Agregar gasto amigo"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Deseas confirmar este gasto? Si continuas, se agregar una tarjeta nueva, con el nombre de usuario de tu companero y se agregar el gasto ahi.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={(e)=>{setShowModalConfirm(false); setDataTemporal(null)}}>No</Button>
                            <Button onClick={()=>{submitData(dataTemporal);setShowModalConfirm(false);}} autoFocus>
                              Si
                            </Button>
                          </DialogActions>
                        </Dialog>
                </TileContainer>
                <Side>
                  {
                    !showLoader ? 
                  <ContainerDetail>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user}
                    onChange={(e)=>{setUser(e.target.value)}}
                    label="Usuario"
                  >
                    {users.map(us => {
                      return <MenuItem value={us.email}>{us.email}</MenuItem>
                    })}
                    </Select>
                    <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nombre} onChange={(e)=>{setNombre(e.target.value)}} />
                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><TextField id="outlined-basic" label="Gasto" variant="outlined" value={gasto} onChange={(e)=>{setGasto(e.target.value)}} /> <FormControlLabel control={<Checkbox  value={parcial} onChange={(e) => {setParcial(e.target.checked);}}/>} label="Valor cuota" /><Tooltip title={<h3 style={{ color: "lightblue" }}>Al activar este check, en el campo gasto tiene que poner el valor de 1 cuota. Sino por default ingrese el valor total del producto. La app lo va a dividir entre las cuotas.</h3>} style={{display: 'block', marginLeft: '-16px'}}>
                          <IconButton>
                            <InfoIcon></InfoIcon>
                          </IconButton>
                        </Tooltip>
                    </div>
                    <div className="container-gastos">
                      <TextField id="outlined-basic" label="Cuotas restantes" disabled={isFijo} variant="outlined" value={quotesLeft} onChange={(e)=>{setQuotesLeft(e.target.value)}} />
                      <FormControlLabel control={<Checkbox  value={isFijo} onChange={(e) => {setIsFijo(e.target.checked); setQuotesLeft('');}}/>} label="Es un gasto fijo" />
                    </div>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={tarjeta}
                    onChange={handleChange}
                    label="Tarjeta"
                  >
                    {tarjetas.map(tarj => {
                      return <MenuItem value={tarj}>{tarj}</MenuItem>
                    })}
                    </Select>
                    <Button variant="contained" onClick={submit}>AGREGAR</Button>

                  </ContainerDetail>
                  : null  }
                </Side>
              </Main>
            </MainWithDetail>
          </Container>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container-gastos {
    display: flex;
    gap: 8px;
  }
`
const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%; 
`;

const TileContainer = styled.div`
  display: flex;
  height: calc(100vh-24px);
  width: 100%;
  gap: 16px;
  margin: 12px;
  border-radius: 6px;
`;

const MainWithDetail = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const Side = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 40%;
  justify-content: center;
  padding: 20px;
`;


export default AddProductFriend;