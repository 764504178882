import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {app,auth} from "../firebase/firebase";
import { ARRAY } from "../utils/base-datos";
import {db} from "../firebase/firebase";
import { collection, query, where, onSnapshot, getDoc, addDoc, doc } from "firebase/firestore";
import { DataService } from "../services/data-service";
import { Autocomplete, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { ColorModeContext, tokens, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { AuthContext } from "../firebase/auth";

const Home = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const colors = tokens(theme.palette.mode);

  const [nombre, setNombre] = useState("");
  const [cuotaInicial, setCuotaInicial] = useState(0);
  const [cuotaFinal, setCuotaFinal] = useState(0);
  const [gastoFijo, setGastoFijo] = useState(false);
  const [unPago, setUnPago] = useState(false);
  const [tarjetas, setTarjetas] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [enumMont, setEnumMonth] = useState({1: 'Enero', 2:'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'});

  const [monthActual, setMonthActual] = useState(enumMont[new Date().getMonth() + 1] + ' ' + new Date().getFullYear());
  const [monthList, setMonthList] = useState([
    {value: 4, name: 'Abril 2023', valueMonth: 4, year: 2023}, {value: 5, name: 'Mayo 2023', valueMonth: 5, year: 2023}, {value: 6, name: 'Junio 2023', valueMonth: 6, year: 2023}, {value: 7, name: 'Julio 2023', valueMonth: 7, year: 2023}, {value: 8, name: 'Agosto 2023', valueMonth: 8, year: 2023}, {value: 9, name: 'Septiembre 2023', valueMonth: 9, year: 2023}, {value: 10, name: 'Octubre 2023', valueMonth: 10, year: 2023}, {value: 11, name: 'Noviembre 2023', valueMonth: 11, year: 2023}, {value: 12, name: 'Diciembre 2023', valueMonth: 12, year: 2023},
    {value: 13, name: 'Enero 2024', valueMonth: 1, year: 2024}, {value: 14, name: 'Febrero 2024', valueMonth: 2, year: 2024}, {value: 15, name: 'Marzo 2024', valueMonth: 3, year: 2024}, {value: 16, name: 'Abril 2024', valueMonth: 4, year: 2024}, {value: 17, name: 'Mayo 2024', valueMonth: 5, year: 2024}, {value: 18, name: 'Junio 2024', valueMonth: 6, year: 2024}, {value: 19, name: 'Julio 2024', valueMonth: 7, year: 2024}, {value: 20, name: 'Agosto 2024', valueMonth: 8, year: 2024}, {value: 21, name: 'Septiembre 2024', valueMonth: 9, year: 2024}, {value: 22, name: 'Octubre 2024', valueMonth: 10, year: 2024}, {value: 23, name: 'Noviembre 2024', valueMonth: 11, year: 2024}, {value: 24, name: 'Diciembre 2024', valueMonth: 12, year: 2024}]);

    const {currentUser} = useContext(AuthContext);

    // DOLAR Y EURO
    const [moneys, setMoneys] = useState({});
    const [listMoney, setListMoney] = useState(['oficial','blue','oficial_euro','blue_euro'])
    const [showMoney, setShowMoney] = useState(false);
    const [enumMoney, setEnumMoney] = useState({'oficial': 'DOLAR OFICIAL', 'blue': 'DOLAR BLUE', 'oficial_euro': 'EURO OFICIAL', 'blue_euro': 'EURO BLUE'});

    useEffect(()=> {
      const docRef = doc(db, "constants", currentUser.email );
      const colRef = doc(docRef, "values", 'TARJETAS');
  
      const docSnap = getDoc(colRef);
  
      docSnap.then(e => {
        if (e?.data()?.value) {
          let tc = e.data().value;
          let arr = tc.split(',')
          arr = arr.map(e=> e.trim()) ;
          setTarjetas(arr)
  
        }
      })
    }, []);
    useEffect(() => {
      setShowLoader(true);
      fetch('https://api.bluelytics.com.ar/v2/latest') .then(response => response.json())
      .then(data => {
        console.log(data)
        setMoneys(data);
        setTimeout(()=> {
          setShowMoney(true);
        }, 2000);
    })
    
    // getProducts();   
    reloadProducts(null);

    setTimeout(()=>{
      setShowLoader(false);
    }, 2000);
  }, []);

  const reloadProducts = (value) => {
    let actualDate = null;
    let actualMonth = value ? value : monthActual;
    setShowLoader(true);
    for (let i = 0; i < monthList.length; i++) {
      if (monthList[i].name === actualMonth) {
        actualDate = new Date(monthList[i].valueMonth.toString() + '/' + 1 + '/'  + monthList[i].year.toString());
        console.log('actualDate: ', actualDate);
        break;
      }
    }

    getProducts(actualDate);
    setShowLoader(false);
    return actualDate;
  }
  const getProducts = (actualDate) => {
    
    const docRef = doc(db, "products", currentUser.email );
    const colRef = collection(docRef, "purchases");

    onSnapshot(colRef, (querySnapshot) => {

      const _products = [];
      querySnapshot.forEach((doc) => {
        let copyItem = Object.assign({}, doc.data());
        let dateProduct = new Date(DateTime.fromSeconds(copyItem.date.seconds));
        let diffMonth = Math.abs(monthDiff(dateProduct, actualDate));
        if ( (copyItem.quotesLeft - diffMonth) > 0 || copyItem.isFijo) {
          if (!copyItem.isFijo){
            copyItem.quotesLeft = copyItem.quotesLeft - diffMonth;
          }
          _products.push(copyItem);

        }
      });
     
      setProducts(_products);
      console.log("Actualizacion data.", _products)
    });
  }
  
  const getTotal = (name) => {
    let count = 0;
    for (let i = 0; i < products.length; i++) {
      if (products[i].tarjeta === name) {
        count += parseInt(products[i].gasto);
      }
    }

    return count;
  }

  const getTotalAmount = (name) => {
    let count = 0;
    for (let i = 0; i < products.length; i++) {
        count += parseInt(products[i].gasto);
    }

    return count;
  }

  const redirectToGastosView = (tc) => {
    window.location.href = '/viewGastos?filterTc=' + tc;
  }
  const monthDiff = (d1, d2) => {
    let months; 
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth(); 
    months += d2.getMonth();
     return months;
  }

  function parsePrice(number){
    let formatter = new Intl.NumberFormat('es-AR', {currency: 'ARS', style: 'currency'});
    return formatter.format(number);
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Container style={{background: colors.primary[400] }}>
              <MainWithDetail>
                <Main>
                  <TileContainer style={{background: colors.primary[400] }}>
                    { !showLoader ? <><div style={{position: 'absolute', top: '24px', left: '15px'}}>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-helper-label">Mes</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={monthActual}
                        label="Mes"
                        onChange={(e)=> {setMonthActual(e.target.value); reloadProducts(e.target.value);}}>
                          {monthList.map(month => {
                            return <MenuItem value={month.name}>{month.name}</MenuItem>

                          })}
                      
                      </Select>
                      </FormControl>
                    </div>
                    <div style={{display: 'flex', gap: '12px'}}>
                      {
                        tarjetas.map(tc =>{
                          return <div style={{background: '#ffcaaf', borderRadius: '6px', padding: '40px 20px', position: 'relative', display:'flex', justifyContent: 'flex-start', width: '200px', alignItems: 'center'}} onClick={()=> {redirectToGastosView(tc)}}>
                            <span style={{position: 'absolute', top: '8px', left: '8px', fontWeight: 'bold'}}>{tc}</span>
                            <span>Total del mes: {parsePrice(parseInt(getTotal(tc)))}</span>
                          </div>
                        })
                      } 
                     <div style={{background: '#dab894', borderRadius: '6px', padding: '40px 20px', position: 'relative', display:'flex', justifyContent: 'flex-start', width: '200px', alignItems: 'center'}}>
                            <span style={{position: 'absolute', top: '8px', left: '8px', fontWeight: 'bold'}}>TOTAL</span>
                            <span>Total del mes: {parsePrice(parseInt(getTotalAmount()))}</span>
                      </div> 
                    </div> </> : null}
                    <div style={{display: 'flex', gap: '12px'}}>
                    {
                      showMoney ? listMoney.map(mon => {
                          return <ContainerDolarito>
                            <div className="title"><span style={{marginTop: '6px'}}>{enumMoney[mon]}</span><span className="-line-separator"></span></div>
                            <div className="precios">
                              <div className="price">
                                  <span>COMPRA</span>
                                  <span>{parsePrice(moneys[mon].value_buy)}</span>
                              </div>
                              <span className="-separator"></span>
                              <div className="price -border-left">
                                  <span>PROMEDIO</span>
                                  <span>{parsePrice(moneys[mon].value_avg)}</span>
                              </div>
                              <span className="-separator"></span>
                              <div className="price -border-left">
                                  <span>VENTA</span>
                                  <span>{parsePrice(moneys[mon].value_sell)}</span>
                              </div>
                            </div>
                          </ContainerDolarito>
                      }) : null
                    }
                    </div>
                    
                  </TileContainer>
                </Main>
                
              </MainWithDetail>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const ContainerDolarito = styled.div`
  display: flex;
  flex-direction: column;
  border: 5px solid green;
  border-radius: 8px;
  width: 240px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .-line-separator {
    display: block;
    border-bottom: 1px solid white;
    width: calc(100% - 20%);
    margin-bottom: 6px;
  }
  
  .precios {
    display: flex;
    align-items: center;
    
  }
  .-separator {
    border-left: 1px solid white;
    height: 65%;
  }
  .price {
    display: flex;
    flex-direction: column;
    padding: 12px;
  }
`
const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%; 
`;

const TileContainer = styled.div`
  display: flex;
  height: calc(100vh-24px);
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding: 100px 20px;
  gap: 16px;
  margin: 12px;
  border-radius: 6px;
  position: relative;
`;

const MainWithDetail = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;



export default Home;